import client from '@/apollo/client';
import registerUserMutation from '@/graphql/mutations/registerUser.graphql';

export default async function registerUser(
  email,
  password,
  username,
  data = {}
) {
  const firstName = data?.firstName ?? '';
  const lastName = data?.lastName ?? '';

  return client
    .mutate({
      mutation: registerUserMutation,
      variables: {
        input: {
          email,
          password,
          username,
          firstName,
          lastName,
        },
      },
    })
    .then(
      (response) =>
        response?.data?.registerUser?.user ?? {
          error: true,
          errorMessage: 'An error occurred while trying to register a user.',
        }
    )
    .catch((error) => {
      return {
        error: true,
        errorMessage: error.message,
      };
    });
}
