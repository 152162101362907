import { USER_FIELDS } from './constants';

export default function populateObj(obj, source) {
  let newObj = { ...obj };

  for (let field in source) {
    if (!USER_FIELDS.includes(field)) {
      continue;
    }

    newObj[field] = source[field];
  }

  return newObj;
}
