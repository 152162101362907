import { useEffect, useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { signIn, useSession } from 'next-auth/client';
import { useRouter } from 'next/router';
import clsx from 'clsx';
import CleanLayout from '@/layouts/CleanLayout';
import LoadingIndicator from '@/components/LoadingIndicator';
import { getFriendlyMessageForError } from '@/lib/authentication';

function LoginPageStripes() {
  return (
    <div
      className="absolute inset-0 transform -skew-y-12 bg-gray-100 bg-gradient-to-tl from-green-100 via-gray-100 to-white flex flex-col items-end"
      style={{ height: '1000px', top: '-600px' }}
    >
      <div className="h-24 w-3/12 bg-gradient-to-l from-green-500 to-transparent z-20 absolute bottom-0 right-0" />
      <div className="h-20 w-2/12 bg-gradient-to-l from-green-500 to-transparent z-20 absolute bottom-0 right-0 mb-24" />
      <div className="h-14 w-3/12 bg-gradient-to-r from-green-500 to-transparent z-20 absolute bottom-0 left-0 -mb-14" />
      <div className="h-20 w-2/12 bg-gradient-to-r from-green-500 to-transparent z-20 absolute bottom-0 left-0" />
    </div>
  );
}

function LoginPageSkeleton() {
  return (
    <div className="flex flex-col items-center justify-center w-full min-h-full relative overflow-hidden">
      <div>
        <LoadingIndicator size="w-14" />
      </div>
    </div>
  );
}

const stripTags = (str) => str.replace(/(<([^>]+)>)/gi, '');

function LoginError({ error }) {
  const errorMessage = getFriendlyMessageForError(error);
  return (
    <div
      className="border border-red-600 text-gray-800 rounded bg-red-50 flex items-start px-3 py-3 space-x-1.5 break-words"
      role="alert"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-5 w-5 text-red-600"
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path
          fillRule="evenodd"
          d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
          clipRule="evenodd"
        />
      </svg>
      <div className="flex-1 leading-5 text-sm">{stripTags(errorMessage)}</div>
    </div>
  );
}

const loginValidationSchema = Yup.object({
  username: Yup.string()
    .max(15, 'Debe tener 15 caracteres o menos')
    .required('Debe escribir un nombre de usuario'),
  password: Yup.string().required('Debe escribir una contraseña'),
});

function LoginPageForm({
  handleChange,
  handleBlur,
  isSubmitting,
  handleSubmit,
  errors,
  values,
  touched,
  isValid,
  error,
  errorMessage,
  setErrorMessage,
}) {
  useEffect(() => {
    if (isSubmitting) {
      setErrorMessage('');
    }
  }, [isSubmitting, setErrorMessage]);

  const usernameHasError = errors.username !== undefined && touched.username;
  const passwordHasError = errors.password !== undefined && touched.password;

  return (
    <form className="space-y-6" onSubmit={handleSubmit}>
      {(errorMessage || error) && <LoginError error={errorMessage || error} />}
      <div className="flex flex-col w-full space-y-2">
        <div className="space-y-4">
          <label
            htmlFor="username"
            className={clsx('space-y-2', isSubmitting && 'select-none')}
          >
            <div className="font-medium sr-only">
              Nombre de usuario o correo electrónico
            </div>
            <input
              placeholder="Nombre de usuario o correo electrónico"
              type="text"
              name="username"
              id="username"
              onChange={(e) => {
                setErrorMessage('');
                handleChange(e);
              }}
              onBlur={handleBlur}
              value={values.username}
              className={clsx(
                'rounded w-full border focus:ring-4 py-2 px-4',
                usernameHasError &&
                  'border-red-400 bg-red-50 focus:bg-white focus:border-red-500 focus:ring-red-100',
                !usernameHasError &&
                  'border-gray-300 focus:border-green-600 focus:ring-green-200',
                isSubmitting && 'opacity-75 pointer-events-none select-none'
              )}
              autoComplete="off"
              required
            />
          </label>
        </div>
        <div className="space-y-4">
          <label
            htmlFor="password"
            className={clsx('space-y-2', isSubmitting && 'select-none')}
          >
            <div className="font-medium sr-only">Contraseña</div>
            <input
              placeholder="Contraseña"
              type="password"
              name="password"
              id="password"
              onChange={(e) => {
                setErrorMessage('');
                handleChange(e);
              }}
              onBlur={handleBlur}
              value={values.password}
              className={clsx(
                'rounded w-full border focus:ring-4 py-2 px-4',
                passwordHasError &&
                  'border-red-400 bg-red-50 focus:bg-white focus:border-red-500 focus:ring-red-100',
                !passwordHasError &&
                  'border-gray-300 focus:border-green-600 focus:ring-green-200',
                isSubmitting && 'opacity-75 pointer-events-none select-none'
              )}
              autoComplete="off"
            />
          </label>
        </div>
      </div>
      <div className="flex items-center w-full">
        <button
          className={clsx(
            'relative flex items-center justify-center w-full text-center text-white font-semibold rounded-full px-6 py-3 leading-6 select-none',
            !isSubmitting &&
              'focus:outline-none focus:ring-4 focus:ring-green-200 hover:bg-green-600 focus:bg-green-600 bg-green-500 ',
            (isSubmitting || !isValid) &&
              'opacity-70 pointer-events-none bg-gray-400'
          )}
          disabled={isSubmitting || !isValid}
          type="submit"
        >
          {isSubmitting && (
            <div className="flex items-center justify-center absolute inset-0 text-white">
              <LoadingIndicator color="text-white" />
            </div>
          )}
          <div className={clsx(isSubmitting && 'invisible')}>
            Iniciar sesión
          </div>
        </button>
      </div>
    </form>
  );
}

export default function LoginPage() {
  const [errorMessage, setErrorMessage] = useState('');
  const [session, loading] = useSession();
  const router = useRouter();

  useEffect(() => {
    if (session && session.user) {
      router.push('/perfil');
    }
  }, [session, router]);

  async function submitForm(values, { setSubmitting }) {
    setSubmitting(true);
    try {
      const { username, password } = values;

      await signIn('aibesorgUserLogin', {
        username,
        password,
        redirect: true,
        callbackUrl: router.query?.redirectTo || '/',
      });
    } catch (error) {
      setSubmitting(false);
      setErrorMessage(error.message);
    }
  }

  if (loading) {
    return <LoginPageSkeleton />;
  }

  if (session) {
    return null;
  }

  const { error } = router.query;

  return (
    <div className="flex flex-col items-center justify-center w-full min-h-full relative overflow-hidden">
      <div className="ring-1 ring-black ring-opacity-10 bg-white rounded-lg lg:rounded-xl px-10 py-8 lg:p-10 w-full lg:w-2/6 space-y-6 lg:shadow-lg relative z-20">
        <div>
          <h2 className="text-xl lg:text-2xl font-semibold lg:font-bold lg:tracking-tight">
            Iniciar sesión en su cuenta
          </h2>
        </div>
        <Formik
          initialValues={{
            username: '',
            password: '',
          }}
          onSubmit={(values, formik) => submitForm(values, formik)}
          validationSchema={loginValidationSchema}
          validateOnMount={true}
        >
          {(formik) => (
            <LoginPageForm
              {...formik}
              error={error}
              errorMessage={errorMessage}
              setErrorMessage={setErrorMessage}
            />
          )}
        </Formik>
      </div>
      <LoginPageStripes />
    </div>
  );
}

LoginPage.layoutProps = {
  Layout: CleanLayout,
};
