import { memo } from 'react';
import PropTypes from 'prop-types';

function CleanLayout({ children }) {
  return <div className="w-full flex flex-col min-h-screen">{children}</div>;
}

CleanLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default memo(CleanLayout);
