import { LOGIN_ERRORS } from './constants';

export default function getFriendlyMessageForError(error) {
  switch (error) {
    case LOGIN_ERRORS.INVALID_USERNAME:
      return (
        'El nombre de usuario no existe en nuestros registros. Si cree que ' +
        'esto es un error, puede ponerse en contacto con nuestros administradores.'
      );

    case LOGIN_ERRORS.INCORRECT_PASSWORD:
      return 'Contraseña incorrecta. Por favor, inténtelo de nuevo.';

    case LOGIN_ERRORS.INVALID_EMAIL:
      return (
        'La dirección de correo electrónico no existe en nuestros registros. ' +
        'Si cree que esto es un error, puede ponerse en contacto con nuestros administradores.'
      );

    case LOGIN_ERRORS.BLOCKED_USER:
      return (
        'El acceso desde su dirección IP ha sido bloqueado por razones de seguridad. ' +
        'Comuníquese con el administrador.'
      );

    case LOGIN_ERRORS.NOT_ALLOWED_LOGIN:
      return (
        'Lo sentimos, pero usted no tiene permisos para acceder a este sistema. ' +
        'Si cree que esto es un error, por favor, puede ponerse en contacto con nuestros administradores.'
      );

    case 'Signin':
      return 'Intente iniciar sesión con otra cuenta.';

    case 'OAuthSignin':
      return 'Intente iniciar sesión con otra cuenta.';

    case 'OAuthCallback':
      return 'Intente iniciar sesión con otra cuenta.';

    case 'OAuthCreateAccount':
      return 'Intente iniciar sesión con otra cuenta.';

    case 'EmailCreateAccount':
      return 'Intente iniciar sesión con otra cuenta.';

    case 'Callback':
      return 'Intente iniciar sesión con otra cuenta.';

    case 'OAuthAccountNotLinked':
      return 'Para confirmar su identidad, inicie sesión con la misma cuenta que utilizó originalmente.';

    case 'EmailSignin':
      return 'Verifique su dirección de correo electrónico.';

    case 'CredentialsSignin':
      return 'No se pudo iniciar sesión. Verifique que la información que proporcionó es correcta.';
  }

  return (
    'Se produjo un error durante el inicio de sesión. Por favor, ' +
    'inténtelo nuevamente y si persiste este error, puede ponerse en ' +
    'contacto con nuestros administradores.'
  );
}
