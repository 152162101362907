import { LOGIN_ERRORS } from './constants';

export default function getErrorCode(error) {
  switch (error) {
    case LOGIN_ERRORS.ERR_BLOCKED_USER:
      return LOGIN_ERRORS.ERR_BLOCKED_USER;

    case LOGIN_ERRORS.AIBES_NOT_ALLOWED_LOGIN:
      return LOGIN_ERRORS.NOT_ALLOWED_LOGIN;
  }

  return error;
}
