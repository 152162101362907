export const MAX_SESSION_AGE = 30 * 24 * 60 * 60

export const USER_FIELDS = [
  'accessToken',
  'refreshToken',
  'tokenExpiration',
  'id',
  'userId',
  'name',
  'username',
  'nickname',
  'nicename',
  'firstName',
  'lastName',
  'email',
  'description',
  'url',
  'avatar',
  'customer',
];

export const LOGIN_ERRORS = {
  INVALID_USERNAME: 'invalid_username',
  INCORRECT_PASSWORD: 'incorrect_password',
  INVALID_EMAIL: 'invalid_email',
  AIBES_NOT_ALLOWED_LOGIN: 'aibes_not_allowed_user_login',
  NOT_ALLOWED_LOGIN: 'not_allowed_login',
  BLOCKED_USER: 'blocked_user',
  ERR_BLOCKED_USER:
    '<strong>ERROR</strong>: Access from your IP address has been blocked for security reasons. Please contact the administrator.',
};
