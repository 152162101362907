import { v4 as uuidv4 } from 'uuid';
import client from '@/apollo/client';
import loginUserMutation from '@/graphql/mutations/loginUser.graphql';

export default async function loginUser(username, password) {
  return client
    .mutate({
      mutation: loginUserMutation,
      variables: {
        input: {
          clientMutationId: uuidv4(),
          username,
          password,
        },
      },
    })
    .then((response) => {
      const ret = {
        ...(response?.data?.login?.user || {}),
        customer: response?.data?.login?.customer,
        accessToken: response?.data?.login?.authToken,
        refreshToken: response?.data?.login?.refreshToken,
        jwtRefreshToken: response?.data?.login?.user?.jwtRefreshToken,
        jwtAuthToken: response?.data?.login?.user?.jwtAuthToken,
        jwtAuthExpiration: response?.data?.login?.user?.jwtAuthExpiration,
      };

      return (
        ret ?? {
          error: true,
          errorMessage: 'An error occurred while attempting to login.',
        }
      );
    })
    .catch((error) => {
      return {
        error: true,
        errorMessage: error.message,
      };
    });
}
