import { v4 as uuid } from 'uuid';
import client from '@/apollo/client';
import REFRESH_AUTH_TOKEN_MUTATION from '@/graphql/mutations/refreshAuthToken.graphql';

export default async function refreshAuthToken(refreshToken) {
  return client
    .mutate({
      mutation: REFRESH_AUTH_TOKEN_MUTATION,
      variables: {
        input: {
          clientMutationId: uuid(),
          jwtRefreshToken: refreshToken,
        },
      },
    })
    .then((response) => {
      return (
        response?.data?.refreshJwtAuthToken?.authToken ?? {
          error: true,
          errorMessage: 'An error occurred while trying to fetch auth token.',
        }
      );
    })
    .catch((error) => {
      return {
        error: true,
        errorMessage: error.message,
      };
    });
}
